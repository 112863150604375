import * as React from 'react'
import { Container, Typography } from '@mui/material'
import { SearchEngineOptimization } from '../components/SearchEngineOptimization'

export default function Page() {
  const title = 'Rules'
  return (
    <Container component="main" maxWidth="md">
      <SearchEngineOptimization
        title={title}
        description="The descriptions of the title conversion rules"
      />
      <Typography component="h1" variant="h2" mb={3}>
        {title}
      </Typography>
      <p>
        Our rules have been mostly taken from{' '}
        <a href="https://www.wikihow.com/Capitalize-a-Book-Title" target="_blank" rel="noreferrer">
          https://www.wikihow.com/Capitalize-a-Book-Title
        </a>
        . The exact documentation you can find there. Here we describe only the rules that are
        applied if you choose title style.
      </p>
      <p>
        First of all, we split the text into lines and sentences and apply rules for each sentence
        or line individually. Some of the rules require deep knowledge of the context of the word.
        Our algorithm might not be aware of the exact meaning of the word, as we handle all the
        action in the front-end and do not send your data is our servers. That's why we will treat
        each word which is the most common usage. This may cause some imperfection so please be
        aware of that.
      </p>
      <p>
        We capitalize the first and the last words of the sentence/line. This rule has no excuses
        and has been executed at first before any other rule.
      </p>
      <p>
        The second rule applies capitalization based on the word length. For APA style it makes all
        words with 5 letters or longer capital. For MLA style, it will capitalize from 4 letters and
        longer. This rule is not applied to the Chicago style as it does not have any rules based on
        the character length.
      </p>
      <p>
        The words "also", "be", "if", "than", "that", "thus", and "when" will always be capitalized
        regardless of the length or part of the speech.
      </p>
      <p>
        We do not capitalize conjunctions, prepositions, and articles if they are not already
        capitalized by the rules above. We apply this rule based on the library which contains all
        the conjunctions prepositions and articles in English.
      </p>
      <p>
        All the other words have been treated as either noun, verbs, or adjectives and should be
        always capitalized. This consideration might not be exactly correct but we find out that
        this process is the most accurate of the ones which do not require any server-side
        processing.
      </p>
      <p>
        All rules have been applied in the order as they described so the second rule might be
        applied only if the condition of the first one was not met.
      </p>
      <p>
        Because some of the rules are based on the context we do not guarantee the correctness of
        the conversion but we have a benchmark with the 500 most popular book titles which we used
        to validate our algorithm performance. Our interface allows you to modify the result after
        capitalization to allow you to correct the result before copying it.
      </p>
    </Container>
  )
}
