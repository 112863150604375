import { createTheme } from '@mui/material'

export const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1330,
    },
  },
  typography: {
    fontFamily: '-apple-system, Roboto, sans-serif, serif',
    caption: {
      fontSize: '.88rem',
    },
    subtitle1: {
      fontFamily: 'serif',
    },
    h1: {
      fontSize: '3rem',
    },
    h2: {
      fontSize: '2.5rem',
    },
    h3: {
      fontSize: '2rem',
    },
    h4: {
      fontSize: '1.6rem',
    },
    h5: {
      fontSize: '1.4rem',
    },
    h6: {
      fontSize: '1rem',
    },
  },
  palette: {
    primary: {
      main: '#0d5d82',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
  },
})

export const gutter = theme.spacing.bind(theme)

export const breakpoints = theme.breakpoints.values

export const colors = theme.palette
