export const initialYear = 2021
export const dateFormat = 'MMM D, YYYY'

export const RULE_SETS = {
  APA: 'APA',
  MLA: 'MLA',
  CHICAGO: 'CHICAGO',
  SENTENCE_CASE: 'SENTENCE_CASE',
  UPPER_CASE: 'UPPER_CASE',
  LOW_CASE: 'LOW_CASE',
  FIRST_LETTER_CASE: 'FIRST_LETTER_CASE',
  ALT_CASE: 'ALT_CASE',
}

export const WORDS_MUST_CAPITALIZE = ['also', 'be', 'if', 'than', 'that', 'thus', 'when']

const PREPOSITIONS = [
  'vs',
  'about',
  'above',
  'across',
  'after',
  'against',
  'along',
  'among',
  'around',
  'at',
  'before',
  'behind',
  'between',
  'beyond',
  'but',
  'by',
  'concerning',
  'despite',
  'down',
  'during',
  'except',
  'following',
  'for',
  'from',
  'in',
  'including',
  'into',
  'like',
  'near',
  'of',
  'off',
  'on',
  'onto',
  'out',
  'over',
  'past',
  'plus',
  'since',
  'throughout',
  'to',
  'towards',
  'under',
  'until',
  'up',
  'upon',
  'up to',
  'with',
  'within',
  'without',
]

const ARTICLES = ['a', 'an', 'the']

const CONJUNCTIONS = ['for', 'and', 'nor', 'but', 'or', 'yet', 'so']

export const WORDS_MUST_NOT_CAPITALIZE = [...ARTICLES, ...CONJUNCTIONS, ...PREPOSITIONS]
