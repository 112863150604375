import * as React from 'react'
import { graphql, Link, useStaticQuery } from 'gatsby'
import { css } from '@emotion/react'
import AppBar from '@mui/material/AppBar'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import IconButton from '@mui/material/IconButton'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import Drawer from '@mui/material/Drawer'
import ListItemText from '@mui/material/ListItemText'
import Box from '@mui/material/Box'
import MenuList from '@mui/material/MenuList'
import logo from '../images/logo.svg'
import MenuItem from '@mui/material/MenuItem'
import { theme } from '../utils/theme'
import { menu } from '../utils/menu'

const logoStyle = css`
  display: flex;
  align-items: center;
  max-width: 100%;
  height: 100%;
  flex-grow: 1;
`

const containerStyle = css`
  display: flex;
  align-items: center;
  min-height: 64px;
`

const desktopMenuStyle = css`
  display: none;

  ${theme.breakpoints.up('sm')} {
    display: block;
  }
`

const mobileMenuStyle = css`
  display: block;

  ${theme.breakpoints.up('sm')} {
    display: none;
  }
`

const query = graphql`
  query HeadingQuery {
    site {
      siteMetadata {
        title
      }
    }
  }
`

export const Header = () => {
  const {
    site: {
      siteMetadata: { title },
    },
  } = useStaticQuery(query)

  const [isMenuOpen, setIsMenuOpen] = React.useState(false)

  const openMenu = React.useCallback(() => setIsMenuOpen(true), [])
  const closeMenu = React.useCallback(() => setIsMenuOpen(false), [])

  return (
    <>
      <AppBar
        position="static"
        css={css`
          color: inherit;
          background-color: transparent;
        `}
      >
        <Container maxWidth="xl" css={containerStyle}>
          <Link to="/" className="no-after" css={logoStyle}>
            <img src={logo} alt={title} width={136} height={54} />
          </Link>
          <div css={desktopMenuStyle}>
            {menu.map((item) => (
              <Button
                key={item.link}
                component={Link}
                to={item.link}
                activeClassName="header-menu-active"
              >
                {item.name}
              </Button>
            ))}
          </div>
          <IconButton
            size="large"
            aria-label="show menu"
            edge="end"
            color="inherit"
            onClick={openMenu}
            css={mobileMenuStyle}
          >
            <MoreVertIcon />
          </IconButton>
        </Container>
      </AppBar>
      <Drawer anchor="right" open={isMenuOpen} onClose={closeMenu}>
        <Box sx={{ width: 250 }} role="presentation" onClick={closeMenu} onKeyDown={closeMenu}>
          <MenuList>
            {menu.map((item, index) => (
              <MenuItem
                key={item.link}
                component={Link}
                to={item.link}
                activeClassName="Mui-selected"
              >
                <ListItemText primary={item.name} />
              </MenuItem>
            ))}
          </MenuList>
        </Box>
      </Drawer>
    </>
  )
}
