import * as React from 'react'
import ThemeProvider from '@mui/material/styles/ThemeProvider'
import CssBaseline from '@mui/material/CssBaseline'
import { css } from '@emotion/react'
import { Header } from './Header'
import { Footer } from './Footer'
import '../styles/global.css'
import { gutter, theme } from '../utils/theme'

const containerStyle = css`
  display: flex;
  flex-direction: column;
  gap: ${gutter(6)};
  justify-content: space-between;
  min-height: 100vh;
`

export const Layout = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div css={containerStyle}>
        <Header />
        {children}
        <Footer />
      </div>
    </ThemeProvider>
  )
}

export default Layout
