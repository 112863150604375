import * as React from 'react'
import { css } from '@emotion/react'
import { SearchEngineOptimization } from '../components/SearchEngineOptimization'
import { Container, Typography } from '@mui/material'
import { gutter, theme } from '../utils/theme'
import { Converter } from '../components/Converter'
import { Link } from 'gatsby'
import { rulesPath } from '../utils/menu'

const mainStyle = css`
  display: flex;
  flex-direction: column;
  gap: ${gutter(4)};
`

export default function Page() {
  return (
    <>
      <SearchEngineOptimization />
      <Container component="main" css={mainStyle} maxWidth="xl">
        <div>
          <Typography component="h1" variant="h4" mb={3}>
            Title Capitalization Tool
          </Typography>
          <Typography component="b">
            This is a tool website that applies title capitalization rules over your text. The text
            is usually a Blog Post, Book, or Article title.
            <br />
            You can also observe how your title will looks like in a book caption, Google search
            results, or in a Wikipedia article title.
          </Typography>
        </div>

        <Converter />
      </Container>
      <Container component="article" maxWidth="md">
        <Typography component="h2" variant="h4" mb={3}>
          Capitalization Rules
        </Typography>
        <Typography component="p" variant="paragraph" mt={1}>
          Here you can find how we exactly capitalize titles. Under the style section, you can find
          all capitalization rules sets that are available for you. Each of the rules behaves
          differently and you need to choose the one that you need, based on your content type and
          purpose of use. Let&#39;s address each rule one by one.
        </Typography>

        <Typography component="h3" variant="h5" mt={3}>
          Title
        </Typography>
        <Typography component="p" variant="paragraph" mt={1}>
          This option is for book or article title capitalization. It applies the generic rules of
          title capitalization to your text. Basically, it capitalizes the words based on their
          type, length, and placement in the sentence. We do not apply the rules that require
          knowledge of the word context. We support three variants of title capitalization.
        </Typography>
        <Typography component="p" variant="paragraph" mt={1}>
          <b>APA</b> rules have the most brought usage for blog posts. We suggest using this style
          for blog posts and behavioral science articles title. This is the default option.
        </Typography>
        <Typography component="p" variant="paragraph" mt={1}>
          <b>MLA</b> rules apply most commonly to book or humanity, literature article titles.
        </Typography>
        <Typography component="p" variant="paragraph" mt={1}>
          <b>Chicago</b> rules are mostly used for humanities and social science article titles.
        </Typography>
        <Typography component="p" variant="paragraph" mt={1}>
          For more information on how each of these rules behaves exactly check out our{' '}
          <Link to={rulesPath}>rules</Link> page.
        </Typography>

        <Typography component="h3" variant="h5" mt={3}>
          Sentence case
        </Typography>
        <Typography component="p" variant="paragraph" mt={1}>
          This style is mostly used for normalizing your text capitalization. Basically, it makes
          your text low case and then capitalizes the first letter of each sentence.
        </Typography>

        <Typography component="h3" variant="h5" mt={3}>
          Upper case and Low case
        </Typography>
        <Typography component="p" variant="paragraph" mt={1}>
          Those are straightforward, the first one makes all letters uppercase, the second one makes
          all letters lowercase. Of course, it doesn&#39;t change any symbols.
        </Typography>

        <Typography component="h3" variant="h5" mt={3}>
          First letter case
        </Typography>
        <Typography component="p" variant="paragraph" mt={1}>
          This one is like a &quot;Sentence case&quot; the only difference is that it&#39;s only
          capitalizing the first letter of the whole text.
        </Typography>

        <Typography component="h3" variant="h5" mt={3}>
          Alt case
        </Typography>
        <Typography component="p" variant="paragraph" mt={1}>
          This one is mostly used for password generation or freaking with the text. This one makes
          each first symbol lowercase and second uppercase. It does not change the symbols but count
          them, so if your first character is a symbol the next one will be uppercase.
        </Typography>
      </Container>
    </>
  )
}
