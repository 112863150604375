class MenuItemData {
  constructor(link, name) {
    this.link = link
    this.name = name
  }
}

export const rulesPath = '/rules/'

export const menu = [
  new MenuItemData('/', 'Home'),
  new MenuItemData(rulesPath, 'Rules'),
  new MenuItemData('/about/', 'About'),
  new MenuItemData('/privacy/', 'Privacy'),
]
