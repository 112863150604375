import * as React from 'react'
import { css } from '@emotion/react'
import { Container } from '@mui/material'
import { Link } from 'gatsby'
import { colors, gutter, theme } from '../utils/theme'
import { initialYear } from '../utils/constants'
import { menu } from '../utils/menu'

const footerStyle = css`
  padding: ${gutter(2)} 0;
  background-color: ${colors.grey[100]};
  color: ${theme.palette.text.secondary};
`

const containerStyle = css`
  display: flex;
  flex-direction: column;
  gap: ${gutter(2)};
  align-items: center;
  justify-content: space-between;

  ${theme.breakpoints.up('sm')} {
    flex-direction: row;
  }
`

const menuStyle = css`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: ${gutter(2)};
`

const linkStyle = css`
  color: ${theme.palette.text.primary};
`

const currentYear = new Date().getFullYear()
const year = currentYear > initialYear ? `${initialYear} - ${currentYear}` : currentYear
export const Footer = () => (
  <footer css={footerStyle}>
    <Container css={containerStyle}>
      © Copyright {year}
      <div css={menuStyle}>
        {menu.map((item) => (
          <Link css={linkStyle} to={item.link} key={item.link} activeClassName="active">
            {item.name}
          </Link>
        ))}
      </div>
    </Container>
  </footer>
)
