import * as React from 'react'
import { Link } from 'gatsby'
import { SearchEngineOptimization } from '../components/SearchEngineOptimization'

const NotFoundPage = () => {
  return (
    <main className="content">
      <SearchEngineOptimization title="Not found" description="The requested page was not found" />
      <h1>Page not found</h1>
      <p>
        Sorry{' '}
        <span role="img" aria-label="Pensive emoji">
          😔
        </span>{' '}
        we couldn’t find what you were looking for.
      </p>
      <p>
        <Link to="/">Go home</Link>.
      </p>
    </main>
  )
}

export default NotFoundPage
