import { RULE_SETS, WORDS_MUST_CAPITALIZE, WORDS_MUST_NOT_CAPITALIZE } from './constants'
import { TreebankWordTokenizer } from './TreebankWordTokenizer'
const wordTokenizer = new TreebankWordTokenizer()

const dashSymbolRegex = /-/
const newLineRegex = /\n/
const startWithSymbolRegex = /^\W/
const sentenceEndingRegex = /[?!.]/g

const capitalizeFirstLetter = (str) =>
  str
    .split(dashSymbolRegex)
    .map((token) => token.charAt(0).toUpperCase() + token.substr(1))
    .join('-')

const splitAndMergeLine = (str, callback) => str.split(newLineRegex).map(callback).join('\n')
const splitAndMergeSentence = (str, callback) => {
  const matchSentenceEnding = [...str.matchAll(sentenceEndingRegex)]
  const chunks = []
  const lastMatch = matchSentenceEnding.reduce((start, { index }) => {
    chunks.push(str.slice(start, index + 1))
    if (str[index + 1] === ' ') {
      return index + 2
    }
    return index + 1
  }, 0)
  if (lastMatch < str.length) {
    chunks.push(str.slice(lastMatch))
  }

  return chunks.map(callback).join(' ')
}
const splitAndMergeWord = (str, callback) => str.split(' ').map(callback).join(' ')

const wordLengthRule = (str, rule) => {
  if (rule === RULE_SETS.APA && str.length >= 5) {
    return true
  }

  if (rule === RULE_SETS.MLA && str.length >= 4) {
    return true
  }

  return false
}

export const capitalize = (originalTitle, rule) => {
  const title = originalTitle.toLowerCase().trim()
  switch (rule) {
    case RULE_SETS.SENTENCE_CASE:
      return sentenceCaps(title)
    case RULE_SETS.LOW_CASE:
      return title
    case RULE_SETS.UPPER_CASE:
      return title.toUpperCase()
    case RULE_SETS.FIRST_LETTER_CASE:
      return title.slice(0, 1).toUpperCase() + title.slice(1)
    case RULE_SETS.ALT_CASE:
      return altCaseCaps(title)
    default:
      return bookTitleCaps(title, rule)
  }
}

export const sentenceCaps = (originalTitle) =>
  splitAndMergeLine(originalTitle, (line) =>
    splitAndMergeSentence(line, (sentence) => {
      const clean = sentence.trim()
      return clean.slice(0, 1).toUpperCase() + clean.slice(1)
    })
  )

export const altCaseCaps = (originalTitle) =>
  splitAndMergeLine(originalTitle, (line) =>
    splitAndMergeSentence(line, (sentence) =>
      splitAndMergeWord(sentence, (chunk) =>
        chunk
          .split('')
          .map((letter, index) => (index % 2 === 1 ? letter.toUpperCase() : letter))
          .join('')
      )
    )
  )

export const bookTitleCaps = (originalTitle, rule) =>
  splitAndMergeLine(originalTitle, (line) =>
    splitAndMergeSentence(line, (sentence) =>
      splitAndMergeWord(sentence, (chunk, chunkIndex, chunks) => {
        const tokens = wordTokenizer.tokenize(chunk)
        const formattedTokens = tokens.map((token, tokenIndex) => {
          if (!token || startWithSymbolRegex.test(token)) {
            return token
          }

          if (
            //First word rule
            (chunkIndex === 0 && tokenIndex === 0) ||
            //Last word rule
            (chunkIndex === chunks.length - 1 && tokenIndex === tokens.length - 1) ||
            //Word length rule
            wordLengthRule(token, rule) ||
            //Hardcoded rule
            WORDS_MUST_CAPITALIZE.includes(token)
          ) {
            return capitalizeFirstLetter(token)
          }

          if (WORDS_MUST_NOT_CAPITALIZE.includes(token)) {
            return token
          }

          return capitalizeFirstLetter(token)
        })

        return formattedTokens.join('')
      })
    )
  )

export const googleStyleTitle = (title) => {
  const titleWords = title.split(' ')
  let googleTitle = ''
  for (const word of titleWords) {
    if (googleTitle.length + word.length <= 60) {
      googleTitle += word + ' '
      continue
    }

    googleTitle += '...'
    break
  }

  return googleTitle.trim()
}
