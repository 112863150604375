import * as React from 'react'
import { css } from '@emotion/react'
import { Grid } from '@mui/material'
import { TextInput } from './TextInput'
import { TitlePresentation } from './TitlePresentation'
import { defaultRule, RuleSelector } from './RuleSelector'
import { theme } from '../utils/theme'
import { capitalize } from '../utils/capitalize'
import { ChangeRuleEvent, ConvertTextEvent, triggerGAEvent } from '../utils/googleAnalytics'

const mobileMenuStyle = css`
  display: block;

  ${theme.breakpoints.up('md')} {
    display: none;
  }
`

const flexGridStyle = css`
  display: flex;
  flex-direction: column;
`

const contentStyle = css`
  flex: 1;
`

export const Converter = () => {
  const textareaRef = React.useRef()
  const [style, setStyle] = React.useState(defaultRule)
  const [mobileNodeRef, setMobileNodeRef] = React.useState()
  const [formattedTitle, setFormattedTitle] = React.useState('')
  const hasSentConvertEvent = React.useRef(false)

  const handleChange = React.useCallback(
    (style) => {
      setFormattedTitle(capitalize(textareaRef.current.value, style))
    },
    [textareaRef]
  )

  const handleTextChange = React.useCallback(() => {
    if (!hasSentConvertEvent.current) {
      hasSentConvertEvent.current = true
      triggerGAEvent(new ConvertTextEvent())
    }
    handleChange(style)
  }, [style, handleChange])

  const handleRuleChange = React.useCallback(
    (rule) => {
      triggerGAEvent(new ChangeRuleEvent(rule))
      setStyle(rule)
      handleChange(rule)
    },
    [handleChange]
  )

  return (
    <>
      <div css={mobileMenuStyle} ref={setMobileNodeRef}></div>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6} css={flexGridStyle}>
          <TextInput textareaRef={textareaRef} onChange={handleTextChange} css={contentStyle} />
        </Grid>
        <Grid item xs={12} md={6} css={flexGridStyle}>
          <TitlePresentation title={formattedTitle} css={contentStyle} />
        </Grid>
      </Grid>
      <RuleSelector onChange={handleRuleChange} mobileNode={mobileNodeRef} />
    </>
  )
}
