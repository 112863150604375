import * as React from 'react'
import { ClickAwayListener, IconButton, Tooltip } from '@mui/material'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { CopyResultEvent, triggerGAEvent } from '../utils/googleAnalytics'

export const Copy = ({ titleRef, ...rest }) => {
  const copyRef = React.useRef()
  const [copied, setCopied] = React.useState(false)

  const onCopy = React.useCallback(() => {
    triggerGAEvent(new CopyResultEvent())
    const modifiedTitleValue =
      (titleRef.current && (titleRef.current.value || titleRef.current.textContent)) || ''
    navigator.clipboard.writeText(modifiedTitleValue).then(() => {
      setCopied(true)
    })
  }, [titleRef])

  const handleTooltipClose = React.useCallback(() => setCopied(false), [])

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <Tooltip
        title="Copied!"
        placement="top"
        arrow
        open={!!copied}
        disableFocusListener
        disableHoverListener
        disableTouchListener
      >
        <IconButton ref={copyRef} onClick={onCopy} aria-label="Copy" {...rest}>
          <ContentCopyIcon />
        </IconButton>
      </Tooltip>
    </ClickAwayListener>
  )
}
