import * as React from 'react'
import { css } from '@emotion/react'
import { getRandomInt } from '../utils/number'

const containerStyle = css`
  max-width: 600px;
  margin-bottom: 30px;
  font-family: arial, sans-serif;
`

const addressStyle = css`
  display: flex;
  align-items: center;
  gap: 4px;
  padding-top: 1px;
  padding-bottom: 2px;
  color: #5f6368;
  font-size: 14px;
  line-height: 1.3;
`

const originStyles = css`
  color: #202124;
`

const pathStyles = css`
  color: #5f6368;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`

const titleStyle = css`
  padding-top: 5px;
  margin-bottom: 5px;
  color: #1a0dab;
  line-height: 1.3;
  font-size: 20px;
  font-family: arial, sans-serif;
`

const contentStyles = css`
  color: #4d5156;
  font-size: 14px;
  line-height: 22px;
`

const fakeDomainList = ['https://example.com', 'https://en.wikipedia.org', 'https://google.com']
const fakePathList = ['wiki › Wiki', 'search › capitalize-book-title', 'Read › Books']
const fakeTitleList = [
  'Capitalization in Titles',
  'Title-Case Capitalization Rules for Headings and Book Titles',
  'How to Capitalize Titles | No Nonsense Grammar - PBS ...',
]
const fakeContentList = [
  'Capitalizing titles of books, movies, etc. can be tricky at first glance, but is easy to figure out. Remember to capitalize the first word, last word, ...',
  'Apr 29, 2019 — Book titles, headlines, and first-level headings are capitalized using title case, in which the first, last, and all major words are ...',
  'Capitalize all words in titles of publications and documents, except a, an, the, at, by, for, in, of, on, to, up, and, as, but, or, and nor. Furthermore, no ...',
]

const DummyContext = React.createContext({
  bookDomain: () => void 0,
  releaseDomain: () => void 0,
  bookPath: () => void 0,
  releasePath: () => void 0,
  bookTitle: () => void 0,
  releaseTitle: () => void 0,
  bookContent: () => void 0,
  releaseContent: () => void 0,
})

const book = (availableList, fakeList, selectedSet) => {
  const index = getRandomInt(0, availableList.length - 1)
  const value = availableList[index]
  const globalIndex = fakeList.indexOf(value)
  selectedSet.add(globalIndex)
  availableList.splice(index, 1)
  return [globalIndex, value]
}

const release = (selectedSet, fakeList, index) => {
  selectedSet.delete(index)
  return fakeList.filter((value, index) => !selectedSet.has(index))
}

export const DummyContextRandomizer = ({ children }) => {
  const selectedDomains = React.useRef(new Set())
  const selectedPaths = React.useRef(new Set())
  const selectedTitles = React.useRef(new Set())
  const selectedContents = React.useRef(new Set())
  const availableDomains = React.useRef([...fakeDomainList])
  const availablePaths = React.useRef([...fakePathList])
  const availableTitles = React.useRef([...fakeTitleList])
  const availableContents = React.useRef([...fakeContentList])

  const bookDomain = React.useCallback(
    () => book(availableDomains.current, fakeDomainList, selectedDomains.current),
    []
  )
  const releaseDomain = React.useCallback((index) => {
    availableDomains.current = release(selectedDomains.current, fakeDomainList, index)
  }, [])

  const bookPath = React.useCallback(
    () => book(availablePaths.current, fakePathList, selectedPaths.current),
    []
  )
  const releasePath = React.useCallback((index) => {
    availableDomains.current = release(selectedPaths.current, fakePathList, index)
  }, [])

  const bookTitle = React.useCallback(
    () => book(availableTitles.current, fakeTitleList, selectedTitles.current),
    []
  )
  const releaseTitle = React.useCallback((index) => {
    availableTitles.current = release(selectedTitles.current, fakeTitleList, index)
  }, [])

  const bookContent = React.useCallback(
    () => book(availableContents.current, fakeContentList, selectedPaths.current),
    []
  )
  const releaseContent = React.useCallback((index) => {
    availableContents.current = release(selectedContents.current, fakeContentList, index)
  }, [])

  return (
    <DummyContext.Provider
      value={{
        bookDomain,
        releaseDomain,
        bookPath,
        releasePath,
        bookTitle,
        releaseTitle,
        bookContent,
        releaseContent,
      }}
    >
      {children}
    </DummyContext.Provider>
  )
}

export const DummyGoogleResult = ({ title, ...rest }) => {
  const {
    bookDomain,
    releaseDomain,
    bookPath,
    releasePath,
    bookTitle,
    releaseTitle,
    bookContent,
    releaseContent,
  } = React.useContext(DummyContext)
  const [domain, setDomain] = React.useState('')
  const [path, setPath] = React.useState('')
  const [fakeTitle, setFakeTitle] = React.useState('')
  const [content, setContent] = React.useState('')

  React.useEffect(() => {
    const [index, value] = bookDomain()
    setDomain(value)
    return () => releaseDomain(index)
  }, [bookDomain, releaseDomain])

  React.useEffect(() => {
    const [index, value] = bookPath()
    setPath(value)
    return () => releasePath(index)
  }, [bookPath, releasePath])

  React.useEffect(() => {
    const [index, value] = bookTitle()
    setFakeTitle(value)
    return () => releaseTitle(index)
  }, [bookTitle, releaseTitle])

  React.useEffect(() => {
    const [index, value] = bookContent()
    setContent(value)
    return () => releaseContent(index)
  }, [bookContent, releaseContent])

  return (
    <div css={containerStyle} {...rest}>
      <div css={addressStyle}>
        <div css={originStyles}>{domain}</div>›<div css={pathStyles}>{path}</div>
      </div>
      <div css={titleStyle}>{title ? title : fakeTitle}</div>
      <div css={contentStyles}>{content}</div>
    </div>
  )
}
