class Event {
  constructor(name, data) {
    this.name = name
    this.data = data
  }
}

export class CopyResultEvent extends Event {
  constructor() {
    super('copy_result', {})
  }
}

export class ConvertTextEvent extends Event {
  constructor() {
    super('convert_text', {})
  }
}

export class ChangeViewEvent extends Event {
  constructor(view) {
    super('change_view', { view })
  }
}

export class ChangeRuleEvent extends Event {
  constructor(rule) {
    super('change_rule', { rule })
  }
}

export const triggerGAEvent = (event) => {
  if (typeof window === 'undefined' || !window.gtag) {
    return
  }
  window.gtag('event', event.name, event.data)
}
