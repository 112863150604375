import * as React from 'react'
import { css } from '@emotion/react'
import FormatClearIcon from '@mui/icons-material/FormatClear'
import { gutter, theme } from '../utils/theme'
import Button from '@mui/material/Button'
import { Card, FormControl, FormHelperText, Grid, TextField } from '@mui/material'

const cardStyle = css`
  display: flex;
`

const formControlStyle = css`
  display: flex;
  flex: 1;
`

const textareaStyle = css`
  display: flex;
  flex: 1;

  .MuiInputBase-root {
    flex: 1;
    align-items: start;
    padding-bottom: ${gutter(6)};
  }

  .MuiInputBase-input {
    font-size: ${theme.typography.h6.fontSize};
  }

  .MuiOutlinedInput-notchedOutline {
    border: none;
  }

  ${theme.breakpoints.up('md')} {
    min-height: 180px;
  }
`

const bottomStyle = css`
  margin-top: calc(-${gutter(5)} + 1px);
  margin-bottom: calc(${gutter(1)} + 1px);
`

export const TextInput = ({ onChange, textareaRef, ...rest }) => {
  const handleClear = React.useCallback(() => {
    textareaRef.current.value = ''
    textareaRef.current.focus()
    onChange()
  }, [onChange, textareaRef])

  const value = (textareaRef && textareaRef.current && textareaRef.current.value) || ''
  const wordsCount = value.split(/[\s.?!]/).filter((word) => !!word).length
  const charactersCount = value.trim().length
  const linesCount = value.split('\n').filter((word) => !!word).length

  return (
    <Card css={cardStyle} {...rest}>
      <FormControl fullWidth css={formControlStyle}>
        <TextField
          css={textareaStyle}
          autoFocus
          placeholder="Type to capitalize"
          area-label="Type to capitalize"
          multiline
          minRows={2}
          inputRef={textareaRef}
          onChange={onChange}
        />
        <FormHelperText css={bottomStyle} component="div">
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item xs="auto">
              {value && `${wordsCount} words • ${charactersCount} characters • ${linesCount} line`}
            </Grid>
            <Grid item xs="auto">
              <Button
                variant="outlined"
                onClick={handleClear}
                size="small"
                startIcon={<FormatClearIcon />}
              >
                Clear
              </Button>
            </Grid>
          </Grid>
        </FormHelperText>
      </FormControl>
    </Card>
  )
}
