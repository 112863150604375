import * as ReactDOM from 'react-dom'
import * as React from 'react'
import { css } from '@emotion/react'
import { theme } from '../utils/theme'
import { RULE_SETS } from '../utils/constants'
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  Radio,
  RadioGroup,
  Select,
  Stack,
  MenuItem,
} from '@mui/material'

const desktopMenuStyle = css`
  display: none;

  ${theme.breakpoints.up('md')} {
    display: block;
  }
`

const mobileMenuStyle = css`
  display: block;

  ${theme.breakpoints.up('md')} {
    display: none;
  }
`

class Rule {
  constructor(name, key, variants) {
    if (!key && (!variants || !variants.length)) {
      throw new Error('Rule should have either key either variants')
    }

    this.name = name
    this.key = key
    this.variants = variants && variants.length ? variants : null
  }
}

const ruleOptions = [
  new Rule('Title: ', 'bookTitle', [
    new Rule('Blog Post / Behavioral Sciences Article (APA)', RULE_SETS.APA),
    new Rule('Book (MLA)', RULE_SETS.MLA),
    new Rule('Social Sciences Article (Chicago)', RULE_SETS.CHICAGO),
  ]),
  new Rule('Sentence case (Sentence first letter only. Like this.)', RULE_SETS.SENTENCE_CASE),
  new Rule('Upper case (ALL LETTERS ARE CAPS)', RULE_SETS.UPPER_CASE),
  new Rule('Low case (no caps letters at all)', RULE_SETS.LOW_CASE),
  new Rule('First letter case (Only the first letter)', RULE_SETS.FIRST_LETTER_CASE),
  new Rule('Alt case (oNlY eVeRy sEcOnD lEtTeR)', RULE_SETS.ALT_CASE),
]

const defaultTopLevelRule = ruleOptions[0]
export const defaultRule = defaultTopLevelRule.variants
  ? defaultTopLevelRule.variants[0].key
  : defaultTopLevelRule.key

export const RuleSelector = React.memo(({ onChange, mobileNode }) => {
  const [selectedRule, setSelectedRule] = React.useState(defaultRule)
  const [selectedRadioRule, setSelectedRadioRule] = React.useState(defaultTopLevelRule.key)
  const selectedVariants = React.useRef(new Map())

  const handleRuleChange = React.useCallback(
    (key) => {
      setSelectedRule(key)
      onChange(key)
    },
    [onChange]
  )

  const onRadioChange = React.useCallback(
    (e) => {
      const radio = ruleOptions.find(({ key }) => key === e.target.value)
      if (!radio) {
        throw new Error('No radio with the key')
      }

      let key = radio.key
      if (radio.variants) {
        key = selectedVariants.current.get(radio.key) || radio.variants[0].key
      }

      setSelectedRadioRule(radio.key)
      handleRuleChange(key)
    },
    [selectedVariants, handleRuleChange]
  )

  const onVariantChange = React.useCallback(
    (ruleKey, e) => {
      setSelectedRadioRule(ruleKey)
      selectedVariants.current.set(ruleKey, e.target.value)
      handleRuleChange(e.target.value)
    },
    [selectedVariants, handleRuleChange]
  )

  const onMobileSelectChange = React.useCallback(
    (e) => {
      const key = e.target.value
      let ruleKey,
        variantKey = null
      for (const rule of ruleOptions) {
        if (rule.key === key) {
          ruleKey = rule.key
          break
        }

        const variant =
          rule.variants && rule.variants.find(({ key: variantKey }) => key === variantKey)
        if (variant) {
          ruleKey = rule.key
          variantKey = variant.key
          break
        }
      }

      setSelectedRadioRule(ruleKey)

      if (!variantKey) {
        handleRuleChange(ruleKey)
        return
      }

      selectedVariants.current.set(ruleKey, variantKey)
      handleRuleChange(variantKey)
    },
    [selectedVariants, handleRuleChange]
  )

  return (
    <>
      <FormControl component="fieldset" css={desktopMenuStyle}>
        <FormLabel component="legend">Style</FormLabel>
        <RadioGroup
          onChange={onRadioChange}
          aria-label="Style of the text conversion"
          value={selectedRadioRule}
          name="rule-radio-group"
        >
          {ruleOptions.map(({ name, key, variants }) => {
            return (
              <FormControlLabel
                value={key}
                key={key}
                control={<Radio />}
                label={
                  <Stack direction="row" alignItems="center" spacing={2}>
                    <div>{name}</div>
                    {variants && (
                      <FormControl fullWidth size="small">
                        <InputLabel id={`style-${key}-rule-variants-label`}>Rule</InputLabel>
                        <Select
                          labelId={`style-${key}-rule-variants-label`}
                          value={selectedVariants.current.get(key) || variants[0].key}
                          label="Rule"
                          onChange={(e) => onVariantChange(key, e)}
                        >
                          {variants.map(({ name, key }) => (
                            <MenuItem key={key} value={key}>
                              {name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                  </Stack>
                }
              />
            )
          })}
        </RadioGroup>
      </FormControl>
      {mobileNode &&
        ReactDOM.createPortal(
          <FormControl css={mobileMenuStyle}>
            <InputLabel id={`style-label`}>Style</InputLabel>
            <Select
              fullWidth
              native
              labelId={`style-label`}
              value={selectedRule}
              label="Style"
              onChange={onMobileSelectChange}
            >
              {ruleOptions.map(({ name, key, variants }) => {
                if (!variants) {
                  return (
                    <option key={key} value={key}>
                      {name}
                    </option>
                  )
                }

                return (
                  <optgroup key={key} label={name}>
                    {variants.map(({ name, key }) => (
                      <option key={key} value={key}>
                        {name}
                      </option>
                    ))}
                  </optgroup>
                )
              })}
            </Select>
          </FormControl>,
          mobileNode
        )}
    </>
  )
})
