import * as React from 'react'
import { Container, Typography } from '@mui/material'
import { SearchEngineOptimization } from '../components/SearchEngineOptimization'

export default function Page() {
  const title = 'About us'
  return (
    <Container component="main" maxWidth="md">
      <SearchEngineOptimization
        title={title}
        description="About the authors of the titlecaps.com"
      />
      <Typography component="h1" variant="h2" mb={3}>
        {title}
      </Typography>

      <p>
        I am a software developer from Armenia. I was looking for a tool that does capitalization
        for my blog posts title and didn’t find one with easy to use interface and without needing
        to jump into the rule description to find which will work for me the best.
      </p>
      <p>
        So I have decided to create one. This tool uses all the rules that I have found on the
        internet, mostly it was based on the WikiHow article:{' '}
        <a href="https://www.wikihow.com/Capitalize-a-Book-Title" target="_blank" rel="noreferrer">
          “How to Capitalize a Book Title”
        </a>
        . I have tried to use as many rules as I can without involving complex AI word parsing
        techniques that will need to implement backend data processing.{' '}
      </p>
      <p>
        And with this, I can ensure you 2 things. First, we don’t process your input data on our
        end, all data was entirely processed in the frontend, on your machine, so it’s completely
        private. Second, this website was made to be blazing fast and WCAG compatible, working on
        any device with an easy access interface in order to be a handy tool for everyone.
      </p>
      <p>
        We can’t guarantee that we strictly follow all the rules as some of the rules require a deep
        understanding of the word context, and as I said we didn’t involve any AI or data processing
        on our end to do so, but we have a benchmark of 500 most popular book titles which we use to
        test and verify our algorithm.
      </p>
      <p>
        If you have any feedback or you have any improvement suggestions you can always contact me
        via email:{' '}
        <a href="mailto:sargismarkosyan5@gmail.com" target="_blank" rel="noreferrer">
          sargismarkosyan5@gmail.com
        </a>
        .
      </p>
      <p>
        Hopefully, you'll find out this product useful, and thank you for using it{' '}
        <span role="img" aria-label="Grinning Face">
          😀
        </span>
        .
      </p>
    </Container>
  )
}
